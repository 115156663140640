.btn {

     min-width: 120px;
     border-radius: $border-radius;

     &.btn-primary,&.btn-info {
          background: $teal;
          background: linear-gradient(to bottom right, lighten($teal, 25%), lighten($teal, 10%));
          border-color: lighten($teal, 15%);
     }

     &.btn-success {
          border-color: darken($green, 5%) !important;

     }

     &.btn-purple {
          background: $purple;
          background: linear-gradient(to bottom right, lighten($purple, 10%), $purple);
          color: $white;
     }
     &.btn-outline-purple {
          color: $purple;
          border: solid 1px $purple;
          background-color: $white;
          box-shadow: none;

          &:hover {
               background: linear-gradient(to bottom right, lighten($purple, 10%), $purple);
               color: $white;
          }
     }

}


@include media-breakpoint-down(sm) {
 
     .btn-md-block {
          display: block !important;
          width: 100% !important;
          margin-left: 0 !important;
          margin-right: 0 !important;
     }
 
 }


@include media-breakpoint-down(xs) {

 
     .btn-md-block {
          display: block !important;
          width: 100% !important;
          margin-left: 0 !important;
          margin-right: 0 !important;
     }
 
 }