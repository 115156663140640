#archk-pdf-modal {

    margin: 0;
    margin-left: auto;
    width: 800px;
    max-width: 90%;

    &.fullscreen {
        width: 100%;
        max-width: 100%;
    }
    
    &.on-call {

        .modal-content {
            max-height: calc(100vh - 40px);
        }
    }

    .toggle-fullscreen {
        position: absolute;
        top: 10px;
        right: 60px;
        color: white;
    }

    .modal-content {
        max-height: 100vh;
        height: 100vh;
        border-radius: 0;
        
    }

    .modal-header, .modal-footer {
        background: #111;
        border-radius: 0;

        .modal-title, button span {
            color: white;

        }
    }

    .modal-header {
        border-bottom: solid 1px #444;
    }
    .modal-footer {
        border-top: solid 1px #444;
    }

    .modal-body {
        padding-left: 50px;

        max-height: calc(100vh - 10px);
        overflow: auto;

    }

}

@include media-breakpoint-down(md) {
    #archk-pdf-modal {
        max-width: 100%;

        .modal-header {
            .fa-expand {
                display: none;
            }
        }

        .modal-body {
            padding: $modal-inner-padding !important;
        }
    }
}
