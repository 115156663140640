.card {
    box-shadow: $box-shadow-sm;
    margin-bottom: 0;
    border-radius: 0;
    position: relative;

    // &:before
    // {
    //   z-index: -1;
    //   position: absolute;
    //   content: "";
    //   bottom: 15px;
    //   left: 10px;
    //   width: 50%;
    //   top: 80%;
    //   max-width:300px;
    //   background: #777;
    //   -webkit-box-shadow: 0 15px 10px #777;
    //   -moz-box-shadow: 0 15px 10px #777;
    //   box-shadow: 0 15px 10px #777;
    //   -webkit-transform: rotate(-3deg);
    //   -moz-transform: rotate(-3deg);
    //   -o-transform: rotate(-3deg);
    //   -ms-transform: rotate(-3deg);
    //   transform: rotate(-3deg);
    // }

    &.card-aggregation {
        a {
            display: block;
            &:hover {
                background-color: $gray-200;
            }
        }

        .card-body {
            padding-left: 0;
            padding-right: 0;
        }

        .row {
            margin-left: 0;
            margin-right: 0;
        }

        .col-6, .col-lg-2 {
            padding-left: 0;
            padding-right: 0;
        }

    }

    .card-title {
        font-weight: bold;
    }

}