#page-form {

    .archk-custom-form {
        position: fixed;
        top: 52px;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 100;
        background-color: $white;
        border-top: solid 2px #ddd;

    }

    .form-control {
        border-radius: 0 !important;
        padding-left: 8px;
        padding-right: 8px;
    }

    textarea::placeholder {
        color: $gray-900 !important;
        opacity: .25;
    }

    .form-header {
        position: fixed;
        left: 0;
        right: 0;
        top: 0;
        border-bottom: solid 1px $gray-300;

        .row {
            margin-bottom: 0;
        }

        span {
            display: inline-block !important;
        }
    }

    .possible-options {
        .badge {
            display: inline-block;
            margin-right: 8px;
            // border: solid 1px $info;
            border-radius: 30px;
            padding: 6px 20px;
            text-transform: none;
            font-size: 14px;
            position: relative;
            padding-right: 28px !important;
            padding-left: 14px !important;

            i {
                font-size: 14px;
                font-weight: bold;
                position: relative;
                top: 1px;
                position: absolute;
                right: 0;
                top: 7px;
            }
        }
    }

    .col-page {
        left: 0;
        width: 50px;

        // border-left: solid 1px $gray-300;
        padding-left: 0 !important;
        padding-right: 0 !important;

        padding-top: 0px !important;

        ul {
            padding-left: 0;
            margin-bottom: 0;

            li {
                cursor: pointer;
                text-align: center;
                list-style: none;
                width: 100%;
                border-bottom: solid 1px $gray-300;
                padding-top: 8px;
                padding-bottom: 8px;
                transition: all .2s;

                &.li-drag {
                    background: $green !important;
                    opacity: .5 !important;
                    color: $white !important;
                }

                &.active {
                    // background-color: $orange;
                    color: white;

                    &:hover {
                        background-color: $teal;

                    }
                }

                &:hover {
                    background-color: $gray-300;
                }
            }
        }

    }

    .col-flow {
        left: 50px;
        border-right: solid 1px $gray-300;
        border-left: solid 1px $gray-300;
        padding-left: 0 !important;
        padding-top: 0 !important;
        z-index: 10;
        background-color: $gray-100 !important;
    }

    .col-selected {
        left: calc(33.3vw + 33.1px);
        border-right: solid 1px $gray-300;
        padding-top: 0 !important;
        bottom: 50px !important;
    }

    .col-overview {
        left: calc(66.6vw + 16.6px);
        padding-top: 0 !important;
        bottom: 50px !important;
    }

    .save-question {
        position: fixed;
        bottom: 0;
        right: 0;
        width: calc(66.6% - 16.6px);
        border-top: solid 1px $gray-300;
        padding-top: 10px;
        padding-bottom: 10px;
        z-index: 2;
        background-color: $white;
    }


    .row {
        margin-left: 0;
        margin-right: 0;

        &.archk-row-normal {
            margin-left: -$spacer;
            margin-right: -$spacer;
        }
    }

    .col {
        padding-left: $card-spacer-x;
        padding-right: $card-spacer-x;
        padding-bottom: $card-spacer-y;
        padding-top: $card-spacer-y;
        position: fixed;
        top: 53px;
        bottom: 0;
        overflow: auto;
        max-width: calc(33.3% - 16.6px);

        .archk-col-header {
            padding: 8px 0;
            border-bottom: solid 1px $gray-400;
            margin-bottom: 10px;

            padding-left: $card-spacer-x / 2;
            padding-right: $card-spacer-x / 2;
            margin-left: -$card-spacer-x;
            margin-right: -$card-spacer-x;

            .btn {
                position: relative;
                top: -4px;
                margin-top: -2px;
            }
        }
        .archk-col-footer {
            padding: 11px 0;
            border-top: solid 1px $gray-400;
        }

    }

    .col.col-flow {
        .question-wrapper {
            .question {
                &.question-error {
                    border: 2px solid $red !important;
                    background: lighten($red, 20) !important;
                }
            }
        }
    }

    .question-wrapper {

        padding: $spacer;
        padding-top: 0;
        padding-bottom: 0;
        margin-top: $spacer * 1.5;

        margin-bottom: 1rem;;
        background-color: $gray-100 !important;

        .question-wrapper {
            padding-right: 0;
            border-left: solid 1px $gray-200;
            border-bottom: solid 1px $gray-200;

            .question-wrapper {
                border-left: solid 1px $gray-300;
                border-bottom: solid 1px $gray-300;

                .question-wrapper {
                    border-left: solid 1px $gray-400;
                    border-bottom: solid 1px $gray-400;
                    .question-wrapper {
                        border-left: solid 1px $gray-500;
                        border-bottom: solid 1px $gray-500;
            
                    }
    
                }
            }
        }

        .indent-icon {
            display: none;
        }

        .nested-level {
            .indent-icon {
                color: $gray-500;
                display: block;
                position: absolute;
                left: 0;
                top: -10px;
                left: -22px;
                transform: rotate(180deg) !important;
                color: $gray-700;
            }
        }

        .question {
            border: 1px dashed $gray-600;
            margin-bottom: .5rem;
            background: white;
            position: relative;

            .question-inner-wrapper {
                padding: $spacer * .5 $spacer;
                cursor: pointer;

            }

            &.question-error {
                border: 2px solid $red !important;
                background: lighten($red, 20) !important;
            }



            &:hover {
                border: 1px solid $tealOriginal;
                background: lighten($tealOriginal, 35%) !important;
                
                .edit-icon {
                    position: absolute;
                }

            }

            &.question-drag {
                background: $green !important;
                opacity: .5 !important;
                color: $white !important;
            }

            &.archk-selected-question {
                background: $tealOriginal !important;
                color: $white !important;

                .edit-icon, .branch-icon {
                    border: solid 1px $white;
                    color: $white !important;
                    &:hover {
                        border: solid 1px $white;
                        background: $tealOriginal;
                        color: $white !important;
                        cursor: pointer;
                        transform: rotate(0);
                    }

                }

            }

            .edit-icon, .branch-icon {
                border: solid 1px $green;
                position: absolute;
                font-size: 14px;
                right: 5px;
                top: 7px;
                padding: 3px 4px;
                border-radius: 100%;
                z-index: 2;
                transition: all .2s;
                transform: rotate(0deg);
                
                &:hover {
                    border: solid 1px $tealOriginal;
                    background: $tealOriginal;
                    color: $white !important;
                    cursor: pointer;
                    // transform: rotate(-45deg);
                }
    
            }

            .branch-icon {
                border: solid 1px $orange;
                right: 35px !important;   
                padding-left: 6px; 
                display: none;
            }
    
           
        }

        .nested-level {

            margin-left: 20px;
    
            .question {
                background: $gray-100;
            }
    
        }
    
        .nested-level-3 {
    
            margin-left: 20px;
    
            .question {
                background: $gray-200;
            }
    
        }

    }

}

 #page-form-action {

    .form-control {
        border-radius: 0;
        border-radius: 4px;
        background: $white;
        border-color: hsl(0, 0%, 80%);
        color: hsl(0, 0%, 20%);
        transition: 0s all;
        font-size: 18px;
        padding: 6px 8px;
        height: auto;
        &:focus {
            border-color: #2584ff;
            color: $gray-900;
            outline: solid 1px #2584ff;
            
        }
    }

    .form-group:focus-within .form-control-label {
        color: $green;
        position: relative;
    }

    .form-control-label {
        font-size: 20px;
        margin-top: $spacer * 0;
        color: $gray-900;
        transition: 0s all;
        margin-bottom: 0;
    }

    .question-address-wrapper {
        .form-control-label {
            font-size: 14px;
            margin-top: 0;
        }
    }

    .question-number {
        color: $gray-600;
    }

    .form-group {
        // margin-bottom: 0;

        p {
            margin-top: 5px !important;
            margin-bottom: 5px !important;
        }
    }

    .question-separator {
        margin-top: -$spacer;
    }

    // make calendar appear on input click
    input[type="date"]::-webkit-calendar-picker-indicator {
        background: transparent;
        bottom: 0;
        color: transparent;
        cursor: pointer;
        height: auto;
        left: 0;
        position: absolute;
        right: 0;
        top: 0;
        width: auto;
    }

    .question-list {

        
        border-radius: 4px;

        .indent-icon {
            display: none;
        }

        .question-list {
            margin-bottom: $spacer * 2;

            &:not(:first-child) {
                margin-top: $spacer * 2;
                // border-top: solid 4px #eee;
            }

            .indent-icon {
                position: absolute;
                left: 0;
                color: $green;
                display: block;
            }

            .question-list-inner {
                border: solid 2px $gray-200;
                padding-bottom: $spacer * 2;
                border-radius: 4px;
            }
        }
    }

    .question-wrapper {
        padding:  $spacer * 2;
        border-bottom: solid 1px $gray-300;
        // margin-bottom: -$spacer;
        // opacity: .8;
        background: rgba(0,0,0,.015);

        &.active {
            // opacity: 1;
            background-color: $white !important;
        }
        // &:focus {
        //     opacity: 1;
        //     background-color: $white;
        // }
        // &:hover {
        //     opacity: 1;
        //     background-color: $white;
        // }

        .question-wrapper {
            // border-left: solid 1px $gray-300;
            // background: rgba(44, 196, 228, .05)
            background: rgba(0,0,0,0);

            &:last-child {
                border-bottom: none;
                margin-bottom: -$spacer * 2;
            }

            // &:first-child {
            //     margin-bottom: -$spacer * 2;
            // }

        }
    }

    .item-identifier {
        padding-left: 20px;
        margin-bottom: 0;
        margin-top: $spacer * 2;
        color: $orange;

    }

    .container {
        padding-left: 0 !important;
        padding-right: 0 !important;
        max-width: 860px;
        border-radius: 4px;
    }

    .page-progress {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        z-index: 10;
        background: $white;
    }

    .main-wrapper {
        margin-top: 44px;
    }

    $width: 40px;

    .step-tracker {

        width: 100%;
        p {
            font-size: $font-size-sm;
            margin-top: $width / 4;
            margin-bottom: 0;
        }

        .tracker {

            list-style: none;
            padding-left: 0;
            text-align: center;
            margin-bottom: 0;

            //calculate the width based on number of children
            @for $i from 1 through 10 {
                li:first-child:nth-last-child(#{$i}),
                li:first-child:nth-last-child(#{$i}) ~ li {

                    width: 100% / $i;
                }
            }

            li {

                position: relative;
                display: inline-block;
                transition: all 2s;
                z-index: 3;
                
                .connection, .finished-connection {
                    width: 45%;
                    display: inline-block;
                    position: absolute;
                    top: $width / 2;
                    background: $gray-200;;
                    height: 5px;
                    right: -$width / 2;
                    border-radius: $width;
                }

                .connection {
                    z-index: 2;
                }

                .finished-connection {
                    left: -$width / 2;
                }

                &:last-child {

                    .connection {
                        display: none;
                    }

                }
                &:first-child {

                    // .connection {
                    //     background: $green;
                    // }

                    .finished-connection {
                        display: none !important;
                    }

                }

                &.current {

                    .finished-connection {
                        background: $green;
                    }
                    .wrapper {
                        border: solid 2px $teal;
                        color: solid 2px $teal;
                    }
                }

                .wrapper {

                    display: inline-block;
                    background: white;;

                    height: $width;
                    width: $width;

                    border: solid 2px $gray-200;
                    border-radius: 100%;
                    text-align: center;
                    font-size: $width / 2;
                    padding-top: $width / 8;
                    cursor: pointer;

                    span.not-done {
                        top: -2px;
                        position: relative;
                    }

                    span.done {
                        top: -2px;
                        position: relative;
                    }

                    .done {
                        display: none;
                    }

                }

                &.finished {

                    .connection, .finished-connection {
                        background: $green;;
                        cursor: default;

                    }

                    

                    .wrapper {


                        background: $green;
                        border-color: $green;
                        color: $white;

                        .done {
                            display: inline-block;
                        }

                        .not-done {
                            display: none;
                        }

                    }

                }

            }

        }

    }



 }

.page-form-modal {

    .possible-options {
        span {
            display: inline-block;
            margin-right: 8px;
            border: solid 1px $info;
            border-radius: 20px;
            padding: 6px 16px;
            text-transform: none;
            font-size: 14px;


            i {
                font-size: 14px;
                font-weight: bold;
                position: relative;
                top: 1px;
            }
        }
    }

}
.archk-form-answers-view {
    .question-wrapper {
        position: relative;
        margin-bottom: 15px;
    }

    .question-wrapper .question-wrapper {
        padding-left: 20px;
    }

    .question-title {
        // font-size: 10px;
        padding: 0px;
        margin-bottom: 0px;
        margin-top: 2px;
        margin-bottom: 4px;
        color: #0a2540;
    }
    .question-answer {
        // font-size: 10px;
        margin-top: 0px;
        margin-bottom: 0;
        color: #0a2540;
        padding: 6px 10px 4px;
        border: solid 1px rgba(218,236,246,1);;
        background: rgba(218,236,246,.6);
        border-radius: 4px;
        display: block;
        min-width: 100px;
        margin-left: 20px;
    }

    .question-answer.highlight-answer {
        background: rgba(251,99,64,.8);
        border: solid 1px rgba(251,99,64,1);
        color: white;
        font-weight: bold;
    }

    .question-answer.no-answer-given {
        background: #f8f8f8;
        border: solid 1px #eee;
        color: #ccc;
    }

    .page-number {
        // font-size: 20px;
        background-color: #eee;
        border-radius: 4px;
        border: solid 2px #fb6340;
        background: rgba(251, 99, 64, .7);
        padding: 4px 8px 0px;
        color: white;
        margin-bottom: 10px;
    }

    .answer-icon {
        position: absolute;
        left: 3px;
        top: 3px;
        width: 15px;
    }
    .answer-icon img{
        max-width: 100%;
    }

    .question-answer-wrapper {
        position: relative;
    }

    .item-wrapper {
        border-left: solid 2px #ddd;
        margin-left: 20px;
        margin-top: 10px;
        padding-top: 10px;
        padding-bottom: 10px;
    }

    .item {
        margin-top: 0;
        margin-bottom: 0;
        color: #fb6340;
        // font-size: 18px;
    }

    .signature {
        margin-top: 10px; 
        max-width: 95%;
        border: solid 2px #ddd;
        border-radius: 4px;
        padding: 6px;
    }

    .upload-image {
        margin-top: 20px; 
        max-width: 95%;
        margin-left: 20px;
        margin-top: 10px;
        margin-bottom: 10px;
        border: solid 2px #ddd;
        border-radius: 4px;
        padding: 0 !important;
    }

}