///////////////////////////////////////////////////
///////////////////////////////////////////////////
// this file combines our starting frameworks with
// the creative tim argon styles
//
// we then add on our own scss at the bottom
// to change from dark to light theme switch
// the variables listed below
//
// new and custom scss for this application goes
// under the ./custom folder
//////////////////////////////////////////////////
//////////////////////////////////////////////////



@import "../argon/vendor/fullcalendar/dist/fullcalendar.min.css";
@import "../argon/vendor/sweetalert2/dist/sweetalert2.min.css";
@import "../argon/vendor/select2/dist/css/select2.min.css";
@import "../argon/vendor/quill/dist/quill.core.css";
@import "../argon/vendor/nucleo/css/nucleo.css";
@import "../argon/vendor/@fortawesome/fontawesome-free/css/all.min.css";


// Bootstrap functions
@import '../argon/scss/bootstrap/functions';
@import "../argon/scss/bootstrap/mixins";

// Argon functions
@import "../argon/scss/custom/functions";

//////////////////////////////////////////////////
//switch the following to change the theme
//////////////////////////////////////////////////

// @import "./variables_dark";
@import "./variables_light";

@import "../argon/scss/bootstrap/variables";

// Argon mixins
@import "../argon/scss/custom/mixins";

// Bootstrap components
@import "../argon/scss/bootstrap/root";
@import "../argon/scss/bootstrap/reboot";
@import "../argon/scss/bootstrap/type";
@import "../argon/scss/bootstrap/images";
@import "../argon/scss/bootstrap/code";
@import "../argon/scss/bootstrap/grid";
@import "../argon/scss/bootstrap/tables";
@import "../argon/scss/bootstrap/forms";
@import "../argon/scss/bootstrap/buttons";
@import "../argon/scss/bootstrap/transitions";
@import "../argon/scss/bootstrap/dropdown";
@import "../argon/scss/bootstrap/button-group";
@import "../argon/scss/bootstrap/input-group";
@import "../argon/scss/bootstrap/custom-forms";
@import "../argon/scss/bootstrap/nav";
@import "../argon/scss/bootstrap/navbar";
@import "../argon/scss/bootstrap/card";
@import "../argon/scss/bootstrap/breadcrumb";
@import "../argon/scss/bootstrap/pagination";
@import "../argon/scss/bootstrap/badge";
@import "../argon/scss/bootstrap/jumbotron";
@import "../argon/scss/bootstrap/alert";
@import "../argon/scss/bootstrap/progress";
@import "../argon/scss/bootstrap/media";
@import "../argon/scss/bootstrap/list-group";
@import "../argon/scss/bootstrap/close";
@import "../argon/scss/bootstrap/modal";
@import "../argon/scss/bootstrap/tooltip";
@import "../argon/scss/bootstrap/popover";
@import "../argon/scss/bootstrap/carousel";
@import "../argon/scss/bootstrap/utilities";
@import "../argon/scss/bootstrap/print";

// Argon components
@import '../argon/scss/custom/components';
@import '../argon/scss/custom/utilities';
@import '../argon/scss/custom/vendors';

// React differences
@import '../argon/scss/react/react-differences';



//////////////////////////////////////////////////
//Add all new scss here and below
//////////////////////////////////////////////////
@import './custom/backgrounds.scss';
@import './custom/buttons.scss';
@import './custom/inputs.scss';
@import './custom/modals.scss';
@import './custom/navs.scss';
@import './custom/resets.scss';
@import './custom/tables.scss';
@import './custom/text.scss';
@import './custom/utility.scss';
@import './custom/footer.scss';
@import './custom/badges.scss';
@import './custom/notifications.scss';
@import './custom/cards.scss';
@import './custom/popups.scss';
@import './custom/dropdowns.scss';
@import './custom/misc.scss';

@import './custom/components/loader_circle.scss';
@import './custom/components/quil.scss';
@import './custom/components/archk-object-search-input.scss';
@import './custom/components/archk-internal-sidebar.scss';
@import './custom/components/archk-messenger.scss';
@import './custom/components/archk-timeline.scss';
@import './custom/components/archk-analysis.scss';
@import './custom/components/archk-pdf-modal.scss';
@import './custom/components/archk-tutorial.scss';
@import './custom/components/archk-forms.scss';
@import './custom/components/archk-alerts.scss';
@import './custom/components/archk-template-docs.scss';
@import './custom/components/archk-step-tracker.scss';

@import './custom/layouts/layout-sidebar.scss';
@import './custom/layouts/layout-sidebar-right.scss';
@import './custom/layouts/layout-auth.scss';
@import './custom/layouts/layout-onboarding.scss';




