
@if ($theme == dark) {
    .text-dark {
        color: $white !important;
    }

    .bg-white.text-dark, .bg-white .text-dark {
        color: $black !important;
    }

}


.text-gradient-success {
    color: $green;
    background: linear-gradient(to right, darken($green, 20%), $green);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.text-gradient-info {
    color: $green;
    background: linear-gradient(to right, darken($teal, 20%), $teal);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.text-gradient-warning {
    color: $green;
    background: linear-gradient(to right, darken($orange, 20%), $orange);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}
.text-gradient-danger {
    color: $green;
    background: linear-gradient(to right, darken($red, 20%), $red);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.text-info-original {
    color: $tealOriginal !important;
}

.overflow-text {
    margin-right: -150px;
}

.text-right .overflow-text {
    margin-left: -150px;
    margin-right: inherit
}

b {
    font-weight: bold;
}

a {
    color: darken($body-color, 10%);
}

.text-sub {
    font-size: $font-size-xs;
}

body {
    .ql-editor {
        border: solid 1px $gray-200 !important;
        border-bottom: solid 1px $gray-200 !important;
    }

    .ql-toolbar {
        border-radius: 0;
        border: solid 1px $gray-200 !important;
    }

    .ql-snow .ql-editor p {
        font-family: $font-family-sans-serif !important;
        font-size: $font-size-sm !important;
    }
}

.text-italic {
    font-style: italic;
}